@import "mixins";

#toast-container {
    font-size: 0.9em;
    user-select: none;

    &.toast-bottom {
        display: block;
        position: fixed;
        z-index: 999999999;
        bottom: 100px;
        left: 50%;
        width: 100%;
        max-width: 600px;
        transform: translateX(-50%);
        contain: none;
        overflow: visible;
    }
    .toast-success,
    .toast-error {
        text-align: left;
        font-weight: 500;
        display: block;
        position: absolute;
        z-index: 999999999;
        width: 100%;
        bottom: var(--risc-base-spacing);
        left: var(--risc-base-spacing);
        right: var(--risc-base-spacing);
        margin: 0;
        padding: 1.2em 1.4em;
        line-height: 1.2em;
        border-radius: 0.2em;
        box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.07);
        animation-name: show-toast;
        animation-duration: 0.6s;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        color: inherit;
        background: #fff;
        border: 1px solid var(--vwui-border-color-light);
    }
    i.icon {
        font-size: inherit;
        width: 2em;
        height: 2em;
        line-height: 2em;
        text-align: center;
        margin: -0.4em 1em -0.4em -0.4em;
        background: var(--vwui--color--text);
        color: #fff;
        border-radius: 50%;
        float: left;
    }
    .toast-success {
        i.icon {
            background-color: var(--vwui-color-success);
        }
    }
    .toast-error {
        i.icon {
            background-color:var(--vwui-color-error);
        }
    }
    small {
        display: inline-block;
        vertical-align: top;
        background: rgba(0, 0, 0, 0.044);
        font-weight: bold;
        font-size: 0.6em;
        padding: 0.5em 0.8em;
        line-height: 1.2em;
        border-radius: 0.3em;
        margin: 0 0 -0.1em 1.5em;

        &::before {
            content: 'CODE: ';
        }
    }
    .toast-close-button {
        display: block;
        float: right;
        font-size: 1.125rem;
        font-weight: bold;
        width: 1.4em;
        height: 1.4em;
        line-height: 1.4em;
        text-align: center;
        margin: -0.25em -0.45em -0.25em 1em;
        background: none;
        border-radius: 50%;
        color: inherit;
        padding: 0;
        @include fancy-transitions;

        &:hover,
        &:focus {
            background: rgba(0, 0, 0, 0.044);
        }
    }
    &::after {
        @include clearfix();
    }
}
@keyframes show-toast {
    from {
        transform: translateY(-1.2em);
    }
    to {
        transform: translateY(0);
    }
}
