@import '~@recognizebv/vwui-core/dist/vwui-components/vwui-components.css';
@import "./assets/scss/mixins";
@import "./assets/scss/ng-dropdown";
@import "./assets/scss/ngx-toastr";
@import "./assets/scss/grid";
@import "./assets/scss/spacing";
@import "./assets/scss/table";
@import "./assets/scss/vwui";
@import "./module/rich-text-editor/global";

// Vendor
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css");

.clear {
    &::after {
        @include clearfix();
    }
}

#a11y-focus-reset:focus,
#a11y-focus-reset:focus-visible {
    outline: none;
    box-shadow: none;
}

.disable-interaction {
    &,
    & * {
        pointer-events: none;
    }
}

:root {
    .image-file-upload {
        &__file-drop {
            display: block;
            overflow: hidden;
            border: 0;
            background: #fff;

            .ngx-file-drop {
                --ngx-file-drop-background: #fff;

                &__drop-zone {
                    position: relative;
                    height: 100px;
                    border: 2px dotted var(--vwui--color--border);
                    border-radius: 5px;

                    &--over {
                        --ngx-file-drop-background: var(--vwui-color-neutral-4);

                        background-color: #fff;
                    }
                }

                &__content {
                    background-image: url("assets/icons/upload.svg");
                    background-size: 50px 50px;
                    background-repeat: no-repeat;
                    background-position: center 25px;
                    background-color: var(--ngx-file-drop-background);
                    cursor: pointer;
                    border-radius: 5px;
                    padding: 0;
                    color: var(--vwui-color-dark);
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                &__browse-btn {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
            }
        }
    }
}
