:root {
    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            max-height: 300px;

            .ng-option {
                white-space: normal;
            }
        }
    }
}
