.grid {
    position: relative;
    clear: both;

    &__col {
        position: relative;
        box-sizing: border-box;
        float: left;
        border: solid transparent;
        border-width: 0 1rem 1rem 0;

        &--spacing-large {
            border-width: 0 3rem 3rem 0;
        }
        &--3 {
            width: 25%;

            @media screen and (max-width: 768px) {
                width: 50%;
            }
        }
        &--5 {
            width: 41.66666666%;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        &--6 {
            width: 50%;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        &--7 {
            width: 58.33333333%;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }
    &__row {
        clear: both;
        margin: 0 -1rem -1rem 0;

        &--spacing-large {
            margin: 0 -3rem -3rem 0;
        }
        &:after {
            @include clearfix();
        }
    }
    &:after {
        @include clearfix();
    }
}
.grid-spacer {
    height: 1rem;

    &--large {
        height: 3rem;
    }
}
