@import "mixins";

:root {
    --vwui-logo--width:170px;
    --vwui-logo--height: auto;

    vwui-logo {
        display: inline-block;
        margin-top: 0.25rem;
    }
}

vwui-button {
    white-space: nowrap;
}

vwui-tab-bar {
    font-weight: var(--vwui-font-weight-semibold);
}

.button-group {
    display: flex;
    align-items: center;
    gap: var(--vwui-size-spacing-xs);
    float: right;

    &--in-header {
        margin: -0.6rem 0;
    }

    vwui-button {
        display: inline-block;
        vertical-align: top;
    }
}

vwui-menu-option {
    vwui-icon {
        --vwui-icon--size: 1rem;

        display: inline-block;
        vertical-align: top;
        margin: 0.1rem 0.3rem 0 0;
    }

    &:focus-visible, &:focus-within {
        outline: 2px solid var(--vwui-color-primary);
    }

    &.is-disabled {
        --vwui-menu-option--bg-hover: var(--vwui-color-light);

        cursor: not-allowed;
        opacity: 0.5;
    }
}

.vwui-button {
    &.is-light {
        --vwui-color-light: #eff1f8;
        --vwui-color-neutral-1: var(--vwui-color-dark);

        vwui-icon {
            margin: -0.15rem -0.5rem 0 -0.5rem;
            --vwui-icon--size: 1rem;
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            color: inherit;
        }
    }
    &.is-outline {
        --color: var(--vwui-border-color-base);
        --border-color: var(--vwui-color-neutral-3);
    }
    &.has-warning {
        --bg: var(--vwui--color--error);
        --color: #fff;
    }
}

.main-wrapper {
    max-width: 75rem;
    margin: 0 auto;
    padding: 0 0 2rem 0;
}

.red-flag,
.flag-outline {
    width: 1rem;
    height: 1rem;
    padding: 0.3rem;
    display: inline-block;
    vertical-align: top;
    box-sizing: content-box;
}

.red-flag {
    color: var(--vwui--color--error);
}

.flag-outline {
    color: var(--vwui-color-neutral-2);
}

.red-flag-title,
{
    font-weight: 500;
}

.info {
    width: 1rem;
    height: 1rem;
    padding: 0.3rem;
    display: inline-block;
    vertical-align: top;
    box-sizing: content-box;
    margin: -0.1rem 0;
    color: inherit;
}

.back-link {
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    outline: 0;
    user-select: none;
    cursor: pointer;

    vwui-icon {
        display: inline-block;
        vertical-align: top;
        width: 0.65rem;
        height: 0.65rem;
        margin: 0 0.5rem 0 0;
        color: inherit;
    }
}

.badge {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 0.9rem;

    &--green,
    &--orange,
    &--red,
    &--primary {
        --vwui-badge--text: #fff;
    }
    &--green {
        --vwui-badge--bg: var(--vwui--color--success);
    }
    &--orange {
        --vwui-badge--bg: var(--vwui--color--warning);
    }
    &--red {
        --vwui-badge--bg: var(--vwui--color--error);
    }
    &--primary {
        --vwui-badge--bg: var(--vwui--color--primary);
    }
    &--header {
        display: inline-block;
        vertical-align: top;
        margin: 0.25rem 0 0 0;
        --vwui-badge--font-weight: bold;
    }
    &--has-remark {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: -2px;
            right: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: var(--vwui--color--error);
            box-shadow: 0 0 0 2px #fff;
        }
    }
    &--with-hover {
        cursor: pointer;
    }
    &--right {
        float: right;
    }
    & + .badge {
        margin-left: 0.4rem;
    }

    vwui-icon {
        display: inline-block;
        vertical-align: top;
        width: 0.9rem;
        height: 0.9rem;
        margin: 0.2rem -0.2rem 0 -0.2rem;
        color: inherit;
    }
}

.vwui-image-input.large {
    width: 100%;
    height: 270px;
}

vwui-textarea {
    --vwui-textarea-height: 10rem;

    &.vwui-textarea--table-column {
        --vwui-textarea-height: calc(3rem + 2px);
    }
    &.vwui-textarea--small {
        --vwui-textarea-height: calc(5rem + 2px);
    }
    textarea {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: var(--vwui-textarea-height);
        resize: vertical;
        font: inherit;
        line-height: inherit;

        &:disabled {
            background: var(--vwui-color-neutral-3);
            pointer-events: auto;
        }
    }
}

// Modal is injected in the app-root.
.large-modal {
    --vwui-modal--max-width: 90rem;
    --vwui-modal--max-height: 90vh;
}

// Modal is injected in the app-root.
.medium-modal {
    --vwui-modal--max-width: 60rem;
    --vwui-modal--max-height: 90vh;
}

// Modal is injected in the app-root.
.medium-width-modal {
    --vwui-modal--max-width: 60rem;
}

vwui-table-row vwui-spinner,
.vwui-spinner--with-margin {
    margin: 1.25em;
}

.vwui-table-actions-threshold {
    position: relative;
    z-index: 2;
    float: right;
    white-space: nowrap;
    height: 0;
    margin: 0.5rem 0.5rem -3rem 0;

    &,
    .vwui-form-group {
        margin-bottom: -3rem !important; // Overrule important from VWUI... :(
    }
    .vwui-form-group__label,
    vwui-input {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: 0;
    }
    .vwui-form-group__label {
        padding-right: 1rem;
    }
    vwui-input {
        width: 5rem;
    }
    & + vwui-table {
        position: relative;
        z-index: 1;
    }
}

vwui-table {
    [slot="table-title"] {
        font-size: var(--vwui--font-size--h4);
        font-weight: var(--vwui-font-weight-semibold);
    }
}

.vwui-table-row--with-hover {
    &:hover {
        cursor: pointer;
        background-color: var(--vwui-color-neutral-1);
    }
}

ng-select {
    &.ng-touched.ng-invalid {
        & > .ng-select-container {
            border-color: var(--vwui-color-error);
        }
    }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: lightgrey;
    }
}

app-user-identity-input.ng-touched.ng-invalid {
    ng-select {
        &.ng-touched {
            & > .ng-select-container {
                border-color: var(--vwui-color-error);
            }
        }
    }
}

vwui-tooltip {
    white-space: nowrap;

    &.is-block {
        display: block;
    }
}
:root .vwui-tooltip-label {
    z-index: 99999999;
}

.flatpickr-calendar {
    &.open {
        z-index: 1000000;
    }
}

vwui-nav {
    position: relative;
    display: block;
    overflow: auto;

    .vwui-nav__header {
        position: sticky;
        top: 0;
        background: var(--vwui-color-neutral-4);
        @include fixed-height(var(--vwui-size-component-header));
    }
}

vwui-nav-item {
    &,
    &:focus,
    & * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-focus-ring-color: rgba(0, 0, 0, 0);
        outline: none;
        user-select: none;
    }
}

.label {
    font-size: medium;
    font-weight: 500;
    padding-bottom: 0.3rem;
}

.vwui-header-divider-bar {
    &__start {
        width: 100%;
    }
}


.modal .modal__content .modal__scroll {
    scroll-padding-top: 5rem;
    scroll-padding-bottom: 5rem;
}
