@mixin fancy-transitions {
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

@mixin tab-focus($color) {
    &:not(:hover):focus {
        outline: 1px dotted $color;
    }
}

@mixin table-col-width($width) {
    width: $width;
    max-width: $width;
}

@mixin fixed-height($height) {
    height: $height;
    min-height: $height;
    max-height: $height;
}

@mixin breakword($max-width) {
    max-width: $max-width;
    width: $max-width;
    word-wrap: break-word;
}

@mixin ellipsis($max-width) {
    @include ellipsis_maxwidth($max-width, $max-width)
}

@mixin ellipsis_maxwidth($width, $max-width) {
    max-width: $max-width;
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin clearfix() {
    content: '';
    display: table;
    width: 100%;
    clear: both;
}

@mixin positionCenter() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    backface-visibility: hidden; // translate# quirck
}

@mixin pdfPageSize($class, $width, $height, $padding) {
    // Preview
    html.#{$class} {
        &,
        body {
            width: 100%;
            height: auto;
            background: var(--vwui-color-neutral-4);

            // Prevents MAC's default preview app for PDF to render black boxes
            --vwui-shadow-base: none;
        }
    }
    app-pdf-page {
        padding: #{$padding}mm;
        margin: 60px auto;
        background: #FFFFFF;
        width: #{$width}mm;
        min-height: #{$height}mm;
        box-shadow: 10px 10px 90px 0 rgba(0,0,0,0.1);
    }

    // Actual PDF
    * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
        background: transparent;
        // Force stopping of all transitions and animations for faster PDF generation
        // !important is required for VWUI... :(
        transition: none !important;
        animation: none !important;
    }
    #pageReadyForPdfGeneration {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
    }
    @media print {
        * {
            -webkit-print-color-adjust: exact;
        }
        html.#{$class} {
            &,
            body {
                // !important is required to overrule the viewport meta... :(
                width: #{$width}mm !important;
                min-width: #{$width}mm !important;
                max-width: #{$width}mm !important;
                height: auto;
                overflow: hidden;
                zoom: 1;
                background: #FFFFFF;
            }
        }
        app-pdf-page {
            padding: 0 #{$padding}mm;
            margin: 0;
            min-height: 0;
            box-shadow: none;
        }
    }
    @page {
        margin: #{$padding}mm 0;
        padding: 0;
        size: #{$width}mm #{$height}mm;
    }
}

@mixin pdfPageSizeA4Landscape() {
    @include pdfPageSize(pdf-page-a4-landscape, 297, 210, 10);
}

@mixin pdfPageSizeA4Portrait() {
    @include pdfPageSize(pdf-page-a4-portrait, 210, 297, 10);
}

@mixin pdfPageSizeA3Landscape() {
    @include pdfPageSize(pdf-page-a3-landscape, 420, 297, 10);
}

@mixin pdfPageSizeA3Portrait() {
    @include pdfPageSize(pdf-page-a3-portrait, 297, 420, 10);
}

@mixin insidePdfPage() {
    @at-root .pdf-page & {
        @content
    }
}
