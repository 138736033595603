@import "../../assets/scss/mixins";

.table-info {
    table-layout: initial;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;

    & > tbody {
        & > tr {
            & > th,
            & > td {
                text-align: left;
                vertical-align: top;
                padding: 0.35rem 0;
            }
            & > th {
                font-weight: 500;
                width: 1px;
                white-space: nowrap;
                padding-right: 1.2rem;
            }
        }
    }
}

.table-row {
    &__text {
        padding: var(--padding-column) var(--padding-side);
    }
}

.table-col {
    vertical-align: top;
    white-space: normal;

    &--bold {
        font-weight: 500;
    }
    &--answer {
        @include table-col-width(90mm);
        white-space: pre-wrap;
    }
    &--nr {
        --padding-heading-right: 0;
        --padding-column-right: 0;

        white-space: nowrap;
        @include table-col-width(13mm);
    }
    &--answer-and-remark {
        --padding-x: 0;

        @include table-col-width(36mm);
    }
    &--score {
        --padding-heading-left: 0;
        --padding-column-left: 0;

        text-align: right;
        @include table-col-width(15mm);
    }
    &--risk-financial-impact {
        width: 28mm;
        white-space: nowrap;
        text-align: right;

        &:hover {
            cursor: pointer;
        }
    }
    &--risk-in-per {
        width: 22mm;
        white-space: nowrap;
        text-align: right;

        &:hover {
            cursor: pointer;
        }
    }
    &--risk-score {
        @include table-col-width(30mm);
        text-align: right;

        &:hover {
            cursor: pointer;
        }
    }

    &[role="cell"] {
        word-break: break-word;
    }
}

.table-legends {
    display: table-cell;
    vertical-align: bottom;
    height: 18mm;

    &__item {
        position: relative;
        text-align: left;
        margin: 0.5mm 0 0 0;
        padding: 0 0 0 2.7mm;

        &--no-margin {
            margin: 0;
            padding: 0;
        }
    }
    &__icon {
        --vwui-icon--size: 2mm;

        position: absolute;
        top: 0;
        left: 0;
        width: var(--vwui-icon--size);
        text-align: center;
        white-space: nowrap;
        display: block;
    }
}

.table-legends-vertical {
    display: block;

    &__item {
        display: inline-block;
        vertical-align: top;
        padding: 1mm 2.5mm 0 0;
        word-break: break-word;
    }
    &__icon {
        --vwui-icon--size: 2mm;

        display: inline-block;
        vertical-align: top;
        margin: 0 0.5mm 0 0;
        width: var(--vwui-icon--size);
    }
}
