editor {
    --editor-border-color: var(--vwui-border-color-light);
    --editor-background: #fff;

    display: block;
    position: relative;
    z-index: 1;

    &:hover {
        --editor-border-color: var(--vwui-input--border-color-hover, var(--vwui-color-neutral-2));
    }

    &.has-focus {
        --editor-border-color: var(--vwui-color-primary);
    }

    &.invalid {
        --editor-border-color: var(--vwui-color-danger);
    }

    &.disabled {
        --editor-border-color: var(--vwui-color-neutral-3);
        --editor-background: var(--vwui-color-neutral-3);

        pointer-events: none;
    }

    .tox-tinymce {
        border: 1px solid var(--editor-border-color);
        border-radius: var(--vwui-input--radius, var(--vwui-size-radius-sm));
    }

    .tox {
        .tox-menubar {
            & + .tox-toolbar,
            & + .tox-toolbar-overlord .tox-toolbar__primary {
                border-bottom: 1px solid var(--editor-border-color);
                background: var(--editor-background);
            }
        }
        .tox-edit-area__iframe {
            background: var(--editor-background);
        }
        &:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
            border-right: 1px solid var(--editor-border-color);
        }
    }
}

.mce-content-body {
    margin: 0;
    padding: 15px 18px;
    font-size: var(--vwui-size-font-base);
    font-family: var(--vwui-font-family-base);
    line-height: var(--vwui-font-line-height-base);
    color: var(--vwui-color-dark);

    &--no-padding {
        padding: 0;
    }

    &:focus,
    &:focus-visible {
        outline: 0;
        box-shadow: none;
    }

    p {
        margin: 0 0 15px 0;
        padding: 0;
    }

    ul, ol {
        margin: 0 0 15px 30px;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
        }
    }
}
